import "./pagination.scss";

import { Link } from "gatsby";
import React from "react";

class Pagination extends React.Component {
  render() {
    const { currentPage, firstPage, navPages, lastPage } = this.props
    return (
      <nav className="pagination-container" aria-label="Page navigation">
        <ul className="pagination">
          {
            firstPage ? (<li key={'first' + firstPage.page} className={`${firstPage.page === currentPage ? 'active' : ''} page-item`}>
              <Link className="page-link" to={firstPage.path}>&lt;&lt;</Link></li>) : ''
          }
          {navPages.map(page => {
            return (<li key={page.page} className={`${page.page === currentPage ? 'active' : ''} page-item`}>
              <Link className="page-link" to={page.path}>{page.label}</Link>
            </li>)
          })
          }
          {
            lastPage ? (<li key={'last' + lastPage.page} className={`${lastPage.page === currentPage ? 'active' : ''} page-item`}>
              <Link className="page-link" to={lastPage.path}>&gt;&gt;</Link></li>) : ''
          }
        </ul>
      </nav>
    )
  }
}

export default Pagination

import { Link, graphql } from "gatsby"

import Breadcrumb from "../components/breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Pagination from "../components/pagination"
import React from "react"
import Seo from "../components/seo"
import TagsList from "../components/tagslist"

class BlogIndex extends React.Component {
  render() {
    const { data, path } = this.props
    const { currentPage, firstPage, navPages, lastPage, subtitle } = this.props.pageContext
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const headerImage = data.file.publicURL

    return (
      <Layout location={this.props.location} title={siteTitle} subtitle={subtitle} meta={data.site.siteMetadata}>
        <Seo title="All posts" imageURL={headerImage} />
        <Breadcrumb path={path} />
        {posts.map(({ node }, idx) => {
          const title = node.frontmatter.title || node.fields.slug
          const image = node.frontmatter.image ? node.frontmatter.image.childImageSharp.gatsbyImageData : null
          // alternate images left/right
          // const imageLocation = idx % 2 ? 'order-sm-last' : ''
          const imageLocation = 'order-sm-last'
          return (
            <article key={node.fields.slug}>
              <h2>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
              <p className="info">
                <span className="date">{node.frontmatter.date}</span>
                <span className="author">{node.frontmatter.author}</span>
              </p>
              <div className="row">
                <div className="col-sm">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                  {(node.frontmatter.description || node.excerpt) ? (<p className="mb-4" > <Link to={node.fields.slug}>Read more...</Link></p>) : ''}
                </div>
                {image ? <div className={`col-sm order-first ${imageLocation}`}>
                  <GatsbyImage image={image} className="img-thumbnail mt-0" alt=""/>
                </div> : null}
              </div>
              <div className="tags"><span>Tags:</span><TagsList tags={node.frontmatter.tags} /></div>
              <hr />
            </article>
          );
        })}
        <Pagination currentPage={currentPage} firstPage={firstPage} navPages={navPages} lastPage={lastPage} />
      </Layout>
    );
  }
}

export default BlogIndex

export const pageQuery = graphql`query blogIndexQuery($image: String!, $slugs: [String!]) {
  site {
    siteMetadata {
      title
      subtitle
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: $image}) {
    publicURL
  }
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {date: {ne: null}, draft: {ne: true}}, fields: {slug: {in: $slugs}}}
  ) {
    edges {
      node {
        excerpt
        body
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMM YYYY")
          author
          title
          description
          tags
          image {
           childImageSharp {
             gatsbyImageData(layout: FULL_WIDTH)
           }
          }
        }
      }
    }
  }
}
`
